// useMdmDataChunks.js
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import pako from "pako";

async function fetchFileData(url, signal) {
  // Determine if the file is gzipped based on the URL.

  const isGz = url.split("?")[0].endsWith(".gz");

  // Explicitly set fetch options;
  // Note: setting credentials to 'omit' ensures no cookies or auth headers are sent.
  const options = {
    signal,
    credentials: "omit",
    // You can also add headers here if needed, but leave Authorization out.
    headers: {}
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`Fetch error: ${response.statusText}`);
  }

  let fileData;
  if (isGz) {
    // If gzipped, read the response as an ArrayBuffer.
    const buffer = await response.arrayBuffer();
    // Decompress the data using pako—first convert ArrayBuffer to Uint8Array.
    fileData = pako.ungzip(new Uint8Array(buffer), { to: "string" });
  } else {
    // Otherwise read it as text.
    fileData = await response.text();
  }

  // Split by newline, filter out empty lines, and parse each line as JSON.
  return fileData
    .trim()
    .split("\n")
    .filter(line => line.trim() !== "")
    .map(line => JSON.parse(line));
}
const fetchMdmData = async ({ queryKey, signal }) => {
  const [, table] = queryKey;
  console.log("fetchMdmData re-fetching:", { table });

  // Request the MDH data from your Lambda endpoint.
  // Notice we no longer pass start/limit since it returns the entire result.
  const response = await axios.post(
    `${process.env.REACT_APP_MDH_LAMBDA_SERVER}/mdhData`,
    { table },
    { headers: { ClientToken: localStorage.getItem("clientToken") }, signal }
  );

  console.log("Lambda response:", response.data);
  let { urls } = response.data;
  // If urls is not an array, wrap it as an array.
  if (!Array.isArray(urls)) {
    urls = [urls];
  }
  let retObj = response.data;

  // Fetch all the files concurrently and flatten their results into one array.
  const fileDataArrays = await Promise.all(urls.map(url => fetchFileData(url, signal)));
  retObj.data = fileDataArrays.flat();

  return retObj;
};

export function useMdmData(table) {
  return useQuery({
    queryKey: ["mdmData", table],
    queryFn: fetchMdmData,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false, // do not refetch when window re-focuses
    refetchOnMount: false, // do not automatically refetch on mount if data is available
    refetchOnReconnect: false // do not refetch on network reconnect
  });
}
