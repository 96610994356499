// /src/components/mdm/components/dataManagement/index.js

import React, { Component } from "react";

import "components/powerBI/components/report/style/style.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addTabToNav } from "components/topLevelNav/reducers/topLevelNavReducer";
import {
  getMdmConfig,
  hydrateMdmConfig,
  // addNewRow,
  removeRows,
  setMdmData,
  getActions,
  setFullLoad,
  setRefresh,
  resetInfo,
  resetError,
  setExternalFilter,
  setLoadedData,
  doSetInfoDialog,
  doSetErrorDialog
} from "components/mdm/reducers/mdmReducer";
import { Link } from "react-router-dom";

import { cloneDeep } from "lodash";
import "../../style.css";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { DotLoader } from "react-spinners";
import { LoadingIndicator } from "lib/loadingIndicator";
import { socket } from "routes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { RefreshIcon } from "lib/icons";
import saveAs from "file-saver";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DMGridContainer from "./dmGridContainer";

class DataManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifiedRows: [],
      deletedRows: [],
      currentTable: [],
      oldData: [],
      rowData: [],
      activeIdx: 0,
      currentAppIdx: 0,
      showDeleteConfirmModal: false,
      showUnsavedChangesModal: false,
      showInvalidSave: false,
      showInfoModal: false,
      dirty: false,
      desc: "",
      info: [],
      errorMessage: "",
      showErrorModal: false,
      saving: false,
      runningAction: false,
      navClickedIdx: null,
      selectedQuickFilters: null,
      activeProject: null,
      activeProjectIdx: 0,
      loaded: false,
      showUploadModal: false,
      primaryTable: null,
      importStatus: "",
      importErrors: [],
      showProgressModal: false,
      importId: "",
      runUpdate: false,
      loadedRows: 0,
      rowCount: 0,
      saveClearKey: 0,
      activeTableIdx: 0
    };
    this.agGrid = React.createRef();
    this.gridReady = this.gridReady.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.setDirty = this.setDirty.bind(this);
    this.removeRows = this.removeRows.bind(this);
    this.actionRunResultHandler = this.actionRunResultHandler.bind(this);
    this.importStatusHandler = this.importStatusHandler.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.setShowUploadModal = this.setShowUploadModal.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.getErrorCSV = this.getErrorCSV.bind(this);
    this.renderImportDataStatus = this.renderImportDataStatus.bind(this);
  }

  componentDidMount() {
    const { loadedData, mdmConfig, mdmApps } = this.props;
    socket.on("ActionRunResult", this.actionRunResultHandler);
    socket.on("ImportStatus", this.importStatusHandler);

    let pathInfo = this.props.location.pathname.split("/")[3]; // e.g. "123+0+1"
    if (pathInfo) {
      const table = pathInfo.split("+")[1];
      // If table is present, set local state
      if (table !== undefined) {
        let currentTableDef = null;
        if (loadedData && mdmConfig[table]) {
          currentTableDef = mdmConfig[table].data;
        }
        this.setState({
          activeTableIdx: table,
          currentTable: currentTableDef
        });
      } else {
        // If no table param, redirect to a default location
        this.props.history.push("/mdm/dataManagement/1+0");
      }
    } else {
      this.props.history.push("/mdm/dataManagement/1+0");
    }

    // On initial mount, if mdmApps are available, hydrate the configuration
    if (mdmApps.length > 0) {
      const currentAppId = this.props.location.pathname.split("/")[3] ? pathInfo.split("+")[0] : mdmApps[0].ID;
      // Load configuration for the current app, if not already loaded
      this.props.hydrateMdmConfig(currentAppId);
      this.props.getMdmConfig(currentAppId, 0);

      // Optionally, add the tab to your navigation if needed
      const findIdx = mdmApps.findIndex(item => item.ID === parseInt(currentAppId, 10));
      if (findIdx !== -1) {
        let project = this.props.location.pathname.split("+")[1] || 0;
        this.props.history.replace("/mdm/dataManagement/" + mdmApps[findIdx].ID + "+" + project);
        this.props.addTabToNav({
          mdmIdx: findIdx,
          appId: mdmApps[findIdx].ID,
          title: mdmApps[findIdx].APP_NAME,
          id: mdmApps[findIdx].ID + "+0+0",
          path: "/mdm/dataManagement/" + mdmApps[findIdx].ID + "+" + project,
          type: "mdh",
          icon: "mdh"
        });
      }

      // Mark as loaded so subsequent changes will trigger componentDidUpdate
      this.setState({ loaded: true, currentAppIdx: currentAppId });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { mdmConfig, info, errorMessage, mdmApps } = this.props;
    const { activeTableIdx, currentAppIdx } = this.state;
    let newAppIdx = this.props.location.pathname.split("dataManagement/")[1].split("+")[0] || 0;

    // If we haven't set up the app config yet and have the mdmApps list, do so:
    if (newAppIdx !== currentAppIdx && mdmApps.length > 0) {
      const findIdx = mdmApps.findIndex(item => item.ID === parseInt(newAppIdx, 10));
      if (findIdx !== -1) {
        // Hydrate config & fetch
        this.props.hydrateMdmConfig(mdmApps[findIdx].ID);
        this.props.getMdmConfig(mdmApps[findIdx].ID, 0);

        let table = this.props.history.location.pathname.split("+")[1] || 0;
        // On first load, push a tab into top-level nav
        this.props.history.replace("/mdm/dataManagement/" + newAppIdx + "+" + table);
        this.props.addTabToNav({
          mdmIdx: findIdx,
          appId: mdmApps[findIdx].ID,
          title: mdmApps[findIdx].APP_NAME,
          id: mdmApps[findIdx].ID + "+0+0",
          type: "mdh",
          icon: "mdh",
          path: "/mdm/dataManagement/" + newAppIdx + "+" + table
        });
        this.setState({ loaded: true, currentAppIdx: newAppIdx, activeTableIdx: table });
      }
    }

    // If the Redux store has new hydrated MDM config, update our local state if needed
    if (prevProps.mdmConfig !== this.props.mdmConfig) {
      if (mdmConfig[activeTableIdx] && mdmConfig[activeTableIdx].data) {
        this.setState({ currentTable: mdmConfig[activeTableIdx].data });
      }
    }

    // If we just got new info or errors from Redux, show them:
    if (info !== "") {
      this.props.resetInfo();
      toast.success(info);
    }
    if (errorMessage !== "") {
      this.props.resetError();
      this.setState({ showErrorModal: true, errorMessage });
    }
  }

  componentWillUnmount() {
    // Clear out data states
    this.props.setLoadedData(false);
    socket.off("ActionRunResult", this.actionRunResultHandler);
    socket.off("ImportStatus", this.importStatusHandler);
  }

  /** Socket event handler for when an action completes */
  actionRunResultHandler(data) {
    const { mdmConfig } = this.props;
    const { activeTableIdx } = this.state;
    let message = this.renderInfoMessage(data);

    if (typeof message === "string" && (message.includes("ERROR") || message.includes("Error"))) {
      toast.error(message);
    } else {
      toast.success(message);
    }

    // Invalidate & refetch the relevant table data with React Query
    const queryClient = this.props.queryClient;
    queryClient.invalidateQueries({
      queryKey: ["mdmData", mdmConfig[activeTableIdx].id],
      exact: false
    });
    queryClient.refetchQueries({
      queryKey: ["mdmData", mdmConfig[activeTableIdx].id],
      exact: false,
      type: "active"
    });

    this.setState({
      showInfoModal: false,
      info: data,
      runningAction: false,
      primaryTable: mdmConfig[activeTableIdx].id
    });
  }

  /** Socket event for import data updates */
  importStatusHandler(data, importObj) {
    const { mdmConfig } = this.props;
    const { activeTableIdx, runUpdate } = this.state;

    // If import is done, optionally refetch the data
    if (data === "complete" && runUpdate) {
      const queryClient = this.props.queryClient;
      queryClient.invalidateQueries({
        queryKey: ["mdmData", mdmConfig[activeTableIdx].id],
        exact: false
      });
      queryClient.refetchQueries({
        queryKey: ["mdmData", mdmConfig[activeTableIdx].id],
        exact: false,
        type: "active"
      });
      this.setState({ runUpdate: false });
    }

    this.setState({
      importStatus: data,
      importErrors: importObj.error,
      importId: importObj.importId
    });
  }

  /** Save all changed rows to server */
  saveHandler() {
    const { activeTableIdx, deletedRows } = this.state;
    const { mdmConfig, queryClient } = this.props;

    this.setState({ saving: true });

    // Collect all current rows from AG-Grid
    const rowData = [];
    this.agGrid.current.gridApi.forEachNode(node => rowData.push(node.data));

    let savedRows = rowData.filter(row => {
      if (!row.crudType || row.crudType === "empty") return false;
      return true;
    });

    // If any row is invalid, we skip saving
    let continueWithSave = true;

    // Convert each row's data to the shape your server expects
    savedRows = savedRows.map(row => {
      let saveColumns = mdmConfig[activeTableIdx].data.filter(conf => conf.editable === true);
      saveColumns = saveColumns.map(col => col.field);

      // The rowData we actually send
      let rowDataEntries = Object.entries(row).filter(item => {
        const [k] = item;
        return k === "ID" || saveColumns.includes(k);
      });

      // If row has an updated array, respect those values
      let updateRD = {};
      if (row.updated) {
        updateRD = row.updated.reduce((obj, key) => {
          obj[key.name] = key.newValue;
          return obj;
        }, {});
      }

      // Overwrite or unify data if needed
      rowDataEntries = rowDataEntries.map(([k, v]) => {
        if (updateRD[k] !== undefined) {
          // If there's a known invalid object, skip or mark invalid
          if (updateRD[k].status === "invalid") {
            continueWithSave = false;
          }
          // If it's an FK, do your ID => name mapping, etc.
          v = updateRD[k];
        }
        // Additional logic for _FK fields
        if (k.includes("_FK") && v !== null && typeof v === "object") {
          if (v.id === undefined) {
            // Possibly find an ID for the matched name, etc.
            // ...
            v = null;
          } else {
            v = v.id;
          }
        }
        // Escape backslashes if needed
        if (typeof v === "string") {
          v = v.replace(/\\/g, "\\\\\\\\");
        }
        return [k, v];
      });

      const newObj = rowDataEntries.reduce((obj, [k, v]) => {
        obj[k] = v;
        return obj;
      }, {});

      const type = row.crudType;
      delete newObj.crudType;
      delete newObj.updated;

      return {
        id: row.ID,
        type,
        rowData: newObj
      };
    });

    // Deleted rows
    deletedRows.forEach(row => {
      savedRows.push({
        id: row.rowData.ID,
        type: "delete",
        rowData: { ID: row.rowData.ID }
      });
    });

    if (!continueWithSave) {
      this.setState({ saving: false, showInvalidSave: true });
      return;
    }
    debugger;
    // Post to server
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/saveMdmData`,
        {
          table: mdmConfig[activeTableIdx].saveTable,
          saveObj: savedRows
        },
        { headers: { ClientToken: localStorage.getItem("clientToken") } }
      )
      .then(response => {
        this.setState({
          modifiedRows: [],
          oldData: [],
          dirty: false,
          saving: false
        });
        if (response.data.success === false) {
          this.setState({
            showErrorModal: true,
            errorMessage: response.data.message
          });
        } else if (response.data.update) {
          // If the server indicates rows were updated, we can re-invalidate the relevant React-Query chunk
          queryClient.invalidateQueries({
            queryKey: ["mdmData", mdmConfig[activeTableIdx].id],
            exact: false
          });
          queryClient.refetchQueries({
            queryKey: ["mdmData", mdmConfig[activeTableIdx].id],
            exact: false,
            type: "active"
          });
          // Clean up local state
          this.setState({ saveObj: [], primaryTable: mdmConfig[activeTableIdx].id, saveClearKey: this.state.saveClearKey + 1 });
        } else {
          this.setState({ saveObj: [] });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ saving: false });
        if (error.response) {
          this.setState({
            showErrorModal: true,
            errorMessage: error.response.data.error
          });
        }
      });
  }

  /** Called when user clicks a different table from the left nav */
  secondaryNavClickHandler(item, idx, overrideDirty = false) {
    const { mdmConfig } = this.props;
    const { activeTableIdx, dirty } = this.state;

    // If the user has unsaved changes, show a modal to confirm losing them
    if (!dirty || overrideDirty) {
      // Cancel any ongoing row fetches in React-Query for the old table
      const queryClient = this.props.queryClient;
      queryClient.cancelQueries({
        queryKey: ["mdmData", mdmConfig[activeTableIdx].id]
      });

      this.props.getActions(mdmConfig[idx].id);

      this.props.setFullLoad(false);

      this.setState({
        currentTable: mdmConfig[idx].data,
        activeTableIdx: idx,
        desc: mdmConfig[idx].desc,
        runningAction: false,
        selectedAction: null,
        primaryTable: mdmConfig[idx].id,
        selectedSecondaryTable: null,
        saving: false
      });
      this.props.history.push(`/mdm/dataManagement/${this.state.currentAppIdx}+${idx}`);
    } else {
      // We do have unsaved changes
      this.setState({ showUnsavedChangesModal: true, navClickedIdx: idx });
    }
  }

  /** Called if user decides to lose unsaved changes */
  cancelHandler(runSecondaryNavClick = false) {
    const { rollBackData } = this.props;
    const { navClickedIdx } = this.state;

    this.props.setMdmData(rollBackData);
    this.props.setRefresh(true);
    this.setState({ dirty: false });

    if (runSecondaryNavClick) {
      this.secondaryNavClickHandler(null, navClickedIdx, true);
      this.setState({ showUnsavedChangesModal: false, navClickedIdx: null });
    }
  }

  handleChange(selectedOption) {
    this.setState({ selectedAction: selectedOption });
  }

  handleProjectChange(selectedOption) {
    const { activeTableIdx } = this.state;
    this.props.hydrateMdmConfig(this.state.currentAppIdx);
    this.props.getMdmConfig(this.state.currentAppIdx, selectedOption.id);

    // Just update local state
    this.setState({
      activeProject: selectedOption,
      activeProjectIdx: selectedOption.id
    });
    // Also push route if needed
    this.props.history.push(`/mdm/dataManagement/${this.state.currentAppIdx}+${activeTableIdx}`);
  }

  handleSecondaryChange = selectedTable => {
    const { dirty, currentAppIdx } = this.state;
    const { mdmConfig } = this.props;
    if (!dirty) {
      this.props.history.push(`/mdm/dataManagement/${currentAppIdx}+${selectedTable.idx}`);
      this.props.getActions(selectedTable.id);

      this.props.setFullLoad(false);

      this.setState({
        currentTable: mdmConfig[selectedTable.idx].data,
        activeTableIdx: selectedTable.idx,
        selectedSecondaryTable: selectedTable,
        primaryTable: selectedTable.id,
        runningAction: false,
        saving: false
      });
    } else {
      // We have unsaved changes
      this.props.history.push(`/mdm/dataManagement/${currentAppIdx}+${selectedTable.idx}`);
      this.setState({
        showUnsavedChangesModal: true,
        navClickedIdx: selectedTable.idx
      });
    }
  };

  runAction() {
    const { selectedAction, activeTableIdx, currentAppIdx } = this.state;
    this.setState({ runningAction: true });

    socket.emit("runAction", {
      token: localStorage.getItem("clientToken"),
      action: selectedAction.id,
      table: this.props.mdmConfig[activeTableIdx].name,
      currentApp: currentAppIdx
    });
  }

  handleselectedFile = event => {
    event.preventDefault();
    this.setState({ uploadFile: event.target.files[0] });
  };

  uploadFile() {
    const { uploadFile, activeTableIdx, activeProject, currentAppIdx, primaryTable } = this.state;
    const { mdmConfig } = this.props;
    if (!uploadFile) return;

    const data = new FormData();
    data.append("file", uploadFile);
    data.append("appId", currentAppIdx);
    data.append("activeIdx", activeTableIdx);
    data.append("primaryTable", primaryTable);
    data.append("saveTable", mdmConfig[activeTableIdx].saveTable);
    data.append("project", activeProject.id);

    axios
      .post(`${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/importData`, data, {
        headers: { ClientToken: localStorage.getItem("clientToken") }
      })
      .then(res => {
        socket.emit("getImportStatus", {
          token: localStorage.getItem("clientToken"),
          importId: res.data.runId
        });
        // We'll watch the socket event to track progress
        this.handleClose();
        this.setState({
          runUpdate: true,
          showProgressModal: true,
          importId: res.data.runId
        });
      });
  }

  setShowUploadModal(state) {
    this.setState({ showUploadModal: state });
  }

  /** The left nav and top bar for selecting tables, actions, etc. */
  renderSecondaryNav() {
    const { mdmConfig, actions, mdmApps } = this.props;
    const {
      activeTableIdx,
      currentAppIdx,
      selectedAction,
      selectedSecondaryTable,
      dirty,
      activeProject,
      runningAction
    } = this.state;

    let currentApp = mdmApps.find(item => item.ID === parseInt(currentAppIdx, 10));
    if (!currentApp) return <div />;

    let projects = currentApp.PROJECTS.map(item => ({
      id: item.PROJECT_ID,
      label: item.PROJECT_NAME,
      value: item.PROJECT_NAME,
      batchId: item.AB_BATCH_ID
    }));

    let primaryTables = mdmConfig.filter(item => item.primaryTable);
    let secondaryTables = mdmConfig
      .filter(item => !item.primaryTable)
      .map(item => ({
        label: item.name,
        value: item.name,
        idx: item.idx,
        id: item.id
      }));

    // Pick whichever project is active
    let currProject = activeProject || projects[0];

    // The “horizontal” nav for primary tables
    let names = primaryTables.map((conf, i) => (
      <div
        style={{ display: "flex", border: "none" }}
        key={conf.idx}
        className={parseInt(activeTableIdx, 10) === conf.idx ? "mdm-active" : ""}>
        <div className={parseInt(activeTableIdx, 10) === conf.idx ? "mdm-circle-selected" : "mdm-circle"}>{i + 1}</div>
        <Link
          onClick={() => this.secondaryNavClickHandler(null, conf.idx)}
          className={"mdm-nav-link " + (parseInt(activeTableIdx, 10) === conf.idx ? "mdm-active" : "")}
          to={`/mdm/dataManagement/${currentAppIdx}+${conf.idx}`}>
          {conf.name}
        </Link>
      </div>
    ));

    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column" }} className="mdm-nav-buttons">
          {projects.length > 1 && (
            <Select
              className="project-dropdown"
              classNamePrefix="react-select"
              placeholder="Select Project"
              value={currProject}
              onChange={this.handleProjectChange}
              options={projects}
            />
          )}

          <div style={{ display: "flex", marginLeft: "auto", alignItems: "center", marginTop: "10px" }}>
            <OverlayTrigger
              placement="top"
              rootClose
              overlay={<Tooltip id="refresh">Refresh the data table</Tooltip>}
              trigger={["hover", "focus"]}>
              <div
                className="refresh-icon"
                style={{ marginRight: "15px", cursor: "pointer" }}
                onClick={() => {
                  // Invalidate queries for the active table
                  const { queryClient, mdmConfig } = this.props;
                  queryClient.invalidateQueries({
                    queryKey: ["mdmData", mdmConfig[activeTableIdx].id],
                    exact: false
                  });
                  queryClient.refetchQueries({
                    queryKey: ["mdmData", mdmConfig[activeTableIdx].id],
                    exact: false,
                    type: "active"
                  });
                  this.props.setFullLoad(false);
                }}>
                <RefreshIcon color="rgba(88, 89, 91, 1)" height="20" />
              </div>
            </OverlayTrigger>

            {!runningAction ? (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div className="run-action">Run Action:</div>
                <Select
                  className="action-dropdown"
                  classNamePrefix="react-select"
                  placeholder="Actions"
                  value={selectedAction}
                  onChange={this.handleChange}
                  options={actions}
                />
                {!this.state.saving ? (
                  <button
                    className={"mdm-button mdm-button-secondary run-button " + (!dirty ? "" : "disabled")}
                    onClick={() => this.runAction()}>
                    Run
                  </button>
                ) : (
                  <button className="run-button disabled">Run</button>
                )}
              </div>
            ) : (
              <div className="running-action" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <DotLoader sizeUnit="px" size={15} color="black" loading />
                <div style={{ marginLeft: "5px" }}>Running</div>
              </div>
            )}

            <button
              onClick={() => this.setShowUploadModal(true)}
              style={{ marginRight: "35px" }}
              className="mdm-button mdm-button-secondary">
              Import Data
            </button>

            {!this.state.saving ? (
              !runningAction ? (
                <button
                  className="mdm-button mdm-button-primary"
                  onClick={() => this.saveHandler()}
                  style={{ display: "flex", marginLeft: "auto", marginRight: "15px" }}>
                  Save
                </button>
              ) : (
                <button className="mdm-button mdm-button-secondary disabled" style={{ display: "flex", marginRight: "15px" }}>
                  Save
                </button>
              )
            ) : (
              <div
                className="mdm-save"
                style={{ marginLeft: "auto", display: "flex", flexDirection: "row", marginRight: "15px" }}>
                <DotLoader sizeUnit="px" size={15} color="black" loading />
                <div style={{ marginLeft: "5px" }}>Saving</div>
              </div>
            )}

            <button className="mdm-button mdm-button-secondary" onClick={() => this.cancelHandler()}>
              Cancel
            </button>
          </div>

          <div style={{ display: "flex" }}>
            <div className="dm-secondary-nav-cont">{names}</div>
            <div style={{ marginLeft: "auto" }}>
              <Select
                className="reference-tables"
                classNamePrefix="react-select"
                placeholder="Reference Tables"
                value={selectedSecondaryTable}
                onChange={this.handleSecondaryChange}
                options={secondaryTables}
              />
            </div>
          </div>
        </div>

        <div>{this.state.desc}</div>
      </div>
    );
  }

  removeRows(rows) {
    const { deletedRows } = this.state;
    let dRows = cloneDeep(deletedRows);

    rows.forEach(row => {
      dRows.push({
        type: "delete",
        idx: row.rowId,
        changedColumns: Object.keys(row.data),
        oldData: row.data,
        rowData: row.data
      });
    });

    rows.forEach(row => {
      if (row.rowId < this.props.data.length - 1) {
        dRows = dRows.map(mRow => {
          if (mRow.idx >= row.rowId && mRow.type !== "delete") {
            mRow.idx = mRow.idx - 1;
          }
          return mRow;
        });
      }
    });

    this.props.removeRows(rows);
    this.props.setRefresh(true);
    this.setState({ deletedRows: dRows, dirty: true });
    this.handleClose();
  }

  handleClose = () => {
    const { activeTableIdx, currentAppIdx } = this.state;
    let idx = parseInt(activeTableIdx, 10);
    this.props.history.push(`/mdm/dataManagement/${currentAppIdx}+${idx}`);
    this.setState({
      showDeleteConfirmModal: false,
      showUnsavedChangesModal: false,
      showInvalidSave: false,
      showInfoModal: false,
      showErrorModal: false,
      showUploadModal: false,
      errorMessage: "",
      showProgressModal: false,
      importErrors: []
    });
  };

  setDirty(state) {
    this.setState({ dirty: state });
  }

  gridReady(agGrid) {
    this.agGrid = agGrid;
  }

  renderInfoMessage(data) {
    if (!data) return "";
    if (typeof data === "string") return data;
    if (Array.isArray(data) && data.length > 0) {
      return data.map((item, i) => (typeof item === "object" ? JSON.stringify(item) : item)).join("\n");
    }
    return JSON.stringify(data);
  }

  getErrorCSV() {
    const { importId } = this.state;
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/getImportErrors`,
        { importId },
        { headers: { ClientToken: localStorage.getItem("clientToken") } }
      )
      .then(response => {
        const blob = new Blob([response.data.csv], {
          type: "text/csv;charset=utf-8"
        });
        saveAs(blob, "errors.csv");
      });
  }

  renderImportDataStatus() {
    const { importErrors, importStatus } = this.state;

    if (importErrors.length === 0) {
      if (importStatus === "complete") {
        return (
          <ModalBody>
            <div>Status: {importStatus}</div>
          </ModalBody>
        );
      } else {
        return (
          <ModalBody>
            <div>Status: {importStatus}</div>
            <DotLoader sizeUnit="px" size={15} color="black" loading />
          </ModalBody>
        );
      }
    } else {
      return (
        <ModalBody>
          <div>There were errors importing your data:</div>
          {importErrors.length === 1 ? (
            <div>{importErrors.length} Row had errors</div>
          ) : (
            <div>{importErrors.length} Rows had errors</div>
          )}
          <div
            onClick={() => this.getErrorCSV()}
            style={{
              color: " rgba(0, 126, 167, 1)",
              textDecoration: "underline",
              cursor: "pointer"
            }}>
            Download Error Report
          </div>
        </ModalBody>
      );
    }
  }

  renderOkButton() {
    const { importStatus } = this.state;
    if (importStatus === "complete" || importStatus === "Failed") {
      return (
        <Button color="primary" onClick={() => this.handleClose()}>
          OK
        </Button>
      );
    }
    return <Button style={{ cursor: "not-allowed" }}>OK</Button>;
  }

  onRowDataReady(gridApi) {
    // Called once the grid rows are truly ready (optional)
  }

  render() {
    const {
      currentTable,
      showDeleteConfirmModal,
      showUnsavedChangesModal,
      showInfoModal,
      showErrorModal,
      errorMessage,
      showInvalidSave,
      showUploadModal,
      showProgressModal
    } = this.state;

    const { loadingConfig, loadedConfig, error } = this.props;

    // If still loading the config or an error occurred
    if (loadingConfig || (!loadedConfig && error !== null)) {
      return <LoadingIndicator paddingTop="227px" />;
    } else if (error) {
      return <div>{error}</div>;
    }

    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {/* Progress Modal for Imports */}
        <Modal isOpen={showProgressModal}>
          <ModalHeader>Importing Data</ModalHeader>
          {this.renderImportDataStatus()}
          <ModalFooter>{this.renderOkButton()}</ModalFooter>
        </Modal>

        {/* File Upload Modal */}
        <Modal isOpen={showUploadModal}>
          <ModalHeader>Upload File</ModalHeader>
          <ModalBody>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                Import files must use an export from the current table. Column headers and row IDs must be present in the
                uploaded XLSX file.
              </div>
              <div style={{ marginTop: "20px" }}>Select a XLSX import file</div>
              <div>
                <input
                  id="files"
                  type="file"
                  name="Upload"
                  onChange={this.handleselectedFile}
                  onClick={e => {
                    e.target.value = null;
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.uploadFile}>
              Import Data
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        {/* Invalid Save Modal */}
        <Modal isOpen={showInvalidSave}>
          <ModalHeader>Save Error</ModalHeader>
          <ModalBody>
            <div>There are some invalid entries (in red) and this table cannot be saved. Fix these issues and try again.</div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal isOpen={showDeleteConfirmModal}>
          <ModalHeader>Delete Row</ModalHeader>
          <ModalBody>
            <div>Are you sure you want to delete the selected row? This cannot be undone.</div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.agGrid.current.checkRemove();
              }}>
              Delete
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        {/* Info Modal */}
        <Modal isOpen={showInfoModal}>
          <ModalHeader>Info</ModalHeader>
          <ModalBody>{this.renderInfoMessage()}</ModalBody>
          <ModalFooter>
            <Button onClick={this.handleClose}>Ok</Button>
          </ModalFooter>
        </Modal>

        {/* Unsaved Changes Modal */}
        <Modal isOpen={showUnsavedChangesModal}>
          <ModalHeader>Unsaved Changes</ModalHeader>
          <ModalBody>
            <div>
              You have unsaved changes. These changes will be lost if you navigate away from this table. Do you want to
              continue?
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                this.cancelHandler(true);
              }}>
              Yes
            </Button>
            <Button onClick={this.handleClose}>No</Button>
          </ModalFooter>
        </Modal>

        {/* Error Modal */}
        <Modal style={{ maxWidth: "80%", maxHeight: "50%" }} isOpen={showErrorModal}>
          <ModalHeader>Error</ModalHeader>
          <ModalBody>
            <div>{errorMessage}</div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleClose}>OK</Button>
          </ModalFooter>
        </Modal>

        {/* Top/Left Nav bars */}
        {this.renderSecondaryNav()}

        {/* The actual data grid container that uses React Query */}
        <DMGridContainer
          setDirty={this.setDirty}
          removeRows={this.removeRows}
          currentTable={currentTable}
          activeIdx={this.state.activeTableIdx}
          mdmConfig={this.props.mdmConfig}
          parentGridReady={this.gridReady}
          onRowDataReady={this.onRowDataReady}
          lastId={this.props.lastId}
          currentAppIdx={this.state.currentAppIdx}
          externalFilter={this.state.externalFilter}
          setExternalFilter={filter => this.props.setExternalFilter(filter)}
          setRefresh={status => this.props.setRefresh(status)}
          // addNewRow={row => this.props.addNewRow(row)}
          history={this.props.history}
          saveClearKey={this.state.saveClearKey}
          onProgressUpdate={(loaded, total, isLoading) => {
            this.setState({ loadedRows: loaded, rowCount: total, isLoading });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mdmConfig: state.mdm.mdmConfig,
  mdmApps: state.mdm.mdmApps,
  loadingConfig: state.mdm.loadingConfig,
  loadedConfig: state.mdm.loadedConfig,
  loadedHydratedConfig: state.mdm.loadedHydratedConfig,
  loadedData: state.mdm.loadedData,
  loadingData: state.mdm.loadingData,
  actions: state.mdm.actions,
  loadingAppendData: state.mdm.loadingAppendData,
  nextRowToLoad: state.mdm.nextRowToLoad,
  rowCount: state.mdm.rowCount,
  lastId: state.mdm.lastId,
  error: state.mdm.error,
  data: state.mdm.data,
  info: state.mdm.info,
  externalFilter: state.mdm.externalFilter,
  errorMessage: state.mdm.errorMessage,
  rollBackData: state.mdm.rollBackData,
  fullLoad: state.mdm.fullLoad,
  tabs: state.topLevelNav.tabs,
  apps: state.app.apps
});

const mapDispatchToProps = dispatch => ({
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  },
  getMdmConfig(appId, projectId) {
    dispatch(getMdmConfig(appId, projectId));
  },
  hydrateMdmConfig(appId) {
    dispatch(hydrateMdmConfig(appId));
  },

  // addNewRow(row) {
  //   dispatch(addNewRow(row));
  // },
  removeRows(rows) {
    dispatch(removeRows(rows));
  },
  setMdmData(data) {
    dispatch(setMdmData(data));
  },
  getActions(table) {
    dispatch(getActions(table));
  },
  setFullLoad(status) {
    dispatch(setFullLoad(status));
  },
  setRefresh(status) {
    dispatch(setRefresh(status));
  },
  resetInfo() {
    dispatch(resetInfo());
  },
  resetError() {
    dispatch(resetError());
  },
  setExternalFilter(filter) {
    dispatch(setExternalFilter(filter));
  },
  setLoadedData(status) {
    dispatch(setLoadedData(status));
  },
  doSetErrorDialog(message) {
    dispatch(doSetErrorDialog(message));
  },
  doSetInfoDialog(message) {
    dispatch(doSetInfoDialog(message));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataManagement));
